<template>
    <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>TGI Fall Conferencwe</h1>
    </header>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import constantData from '@/json/data.json'

export default {
    name: 'tgi_fall_conference',
    data () {
        return {}
    },
    components: {},
    computed: {
        ...mapGetters({
            userId: 'user/userId'
        })
    },
    created() {
        window.open(`${constantData.fall_conference}${this.userId}`)
        this.$router.push({ path: '/' })
    },

}
</script>

<style lang="scss">